import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import type { AlertStatus } from "@chakra-ui/react";

export interface FlashProp {
  show: boolean;
  status?: AlertStatus;
  text?: string;
  title?: string;
  onClose?: () => void;
}

export function Flash({ show, status, text, title, onClose }: FlashProp) {
  const alertTitle = title ? <AlertTitle>{title}</AlertTitle> : undefined;

  return show ? (
    <Alert status={status} marginBottom={4}>
      <AlertIcon />
      <Box>
        {alertTitle}
        <AlertDescription>{text}</AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
      />
    </Alert>
  ) : (
    <></>
  );
}
