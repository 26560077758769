import { CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  As,
  ButtonGroup,
  HStack,
  IconButton,
  Spacer,
  Tag,
} from "@chakra-ui/react";
import { Badge, Box, Checkbox, Flex, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { GroceryItem } from "../Data";
import { GroceryItemEditor } from "./GroceryItemEditor";

interface GroceryItemViewerProps {
  item: GroceryItem;
  onEdit: (item: GroceryItem) => Promise<GroceryItem>;
  onCheck: (item: GroceryItem) => Promise<void>;
  onDelete: (item: GroceryItem) => Promise<void>;
  locked?: boolean;
}

type GroceryItemEditingState = "viewing" | "editing";

export function GroceryItemViewer({
  item,
  onEdit,
  onCheck,
  onDelete,
  locked = false,
}: GroceryItemViewerProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [editorState, setEditorState] = useState(
    "viewing" as GroceryItemEditingState
  );
  const [editorKey, setEditorKey] = useState(0); // A hack to unmount and remount the editor to reset its state when the edit button is clicked.

  const isControlsDisabled = isSaving || locked;

  const tags = !item.tags
    ? []
    : item.tags.split(",").map((tag: string, index: number) => {
        const splittedTag = tag.split("|", 2);
        let colorScheme = undefined;
        if (splittedTag.length === 2) {
          colorScheme = splittedTag[0];
          // TODO: check for valid colorScheme.
          tag = splittedTag[1];
        }
        return (
          <Tag colorScheme={colorScheme} key={index}>
            {tag}
          </Tag>
        );
      });

  const textAs = item.done ? ("s" as As<any>) : undefined;
  const textColor = item.done ? "gray.400" : undefined;

  // Deal with editor state
  let editButton;
  if (editorState === "viewing") {
    const handleEditClick = function () {
      setEditorState("editing");
    };

    editButton = (
      <IconButton
        icon={<EditIcon />}
        aria-label="Edit"
        onClick={handleEditClick}
      />
    );
  } else if (editorState === "editing") {
    const handleEditClick = function () {
      setEditorState("viewing");
      setEditorKey(editorKey + 1);
    };

    editButton = (
      <IconButton
        icon={<CloseIcon />}
        aria-label="Cancel edit"
        onClick={handleEditClick}
      />
    );
  }

  const handleSave = async function (edittedItem: GroceryItem) {
    try {
      setIsSaving(true);
      const newEditorItem = await onEdit(edittedItem);
      setEditorState("viewing");
      return newEditorItem;
    } catch (e) {
      return edittedItem;
    } finally {
      setIsSaving(false);
    }
  };

  const handleCheckboxClick = async function (
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    try {
      setIsSaving(true);
      await onCheck(item);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteClick = async function () {
    try {
      setIsSaving(true);
      await onDelete(item);
    } finally {
      setIsSaving(false); // Still need to do this in case the deletion failed.
    }
  };

  return (
    <VStack align="stretch" spacing={1}>
      <Flex>
        <Box padding={2}>
          <Checkbox
            isChecked={item.done}
            isDisabled={isControlsDisabled}
            as={textAs}
            color={textColor}
            onChange={handleCheckboxClick}
          >
            {item.name}
            <Badge
              marginLeft={1}
              variant="outline"
              as={textAs}
              color={textColor}
            >
              {item.quantity}
            </Badge>
          </Checkbox>
        </Box>
        <Spacer />
        <HStack spacing={3}>
          <HStack spacing={1}>{tags}</HStack>
          <ButtonGroup size="sm" variant="outline" spacing={1}>
            {editButton}
            <IconButton
              colorScheme="red"
              icon={<DeleteIcon />}
              aria-label="Delete"
              onClick={handleDeleteClick}
            />
          </ButtonGroup>
        </HStack>
      </Flex>

      <Box display={editorState === "viewing" ? "none" : undefined}>
        <GroceryItemEditor
          key={editorKey}
          item={item}
          isDisabled={isControlsDisabled}
          onSave={handleSave}
          saveButtonLabel="Save"
          size="sm"
        />
      </Box>
    </VStack>
  );
}
