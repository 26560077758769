export function getKnownListKeys(): string[] {
  const listKeysData = localStorage.getItem("listKeys");
  let listKeys: string[] = [];
  if (listKeysData !== null) {
    try {
      listKeys = JSON.parse(listKeysData);
    } catch (e) {
      console.log(
        `failed to parse listKeys with value: '${listKeysData}', resetting`
      );
      listKeys = [];
    }
  }

  return listKeys;
}

export function addKnownListKey(key: string) {
  const keys = getKnownListKeys();
  if (keys.indexOf(key) === -1) {
    keys.push(key);
    console.log(`updating local storage list keys: ${keys}`);
    localStorage.setItem("listKeys", JSON.stringify(keys));
  }
}

export function removeKnownListKey(removedKey: string) {
  let keys = getKnownListKeys();
  keys = keys.filter((key) => key !== removedKey);
  console.log(`updating local storage list keys: ${keys}`);
  localStorage.setItem("listKeys", JSON.stringify(keys));
}
