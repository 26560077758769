import AllListsViewer from "./components/AllListsViewer";
import GroceryList from "./components/GroceryList";

export default function App() {
  return window.location.pathname === "/" ? (
    <AllListsViewer />
  ) : (
    <GroceryList />
  );
}
