import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  IconButton,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { createListIfNotExists, listLists } from "../Api";
import { GroceriesList } from "../Data";
import {
  addKnownListKey,
  getKnownListKeys,
  removeKnownListKey,
} from "../LocalStorage";

type ListRowProps = {
  list: GroceriesList;
  onDelete: (listKey: string) => void;
};

function ListRow({ list, onDelete }: ListRowProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      onDelete(list.key);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <HStack>
      <Button as="a" width="100%" href={`/${list.key}`}>
        {list.name}
      </Button>
      <IconButton
        icon={<DeleteIcon />}
        isLoading={isDeleting}
        aria-label="Forget list"
        colorScheme="red"
        onClick={handleDelete}
        list-key={list.key}
      />
    </HStack>
  );
}

export default function AllListsViewer() {
  const [groceryListName, setGroceryListName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [knownLists, setKnownLists] = useState([] as GroceriesList[]);

  useEffect(() => {
    const listKeys = getKnownListKeys();
    console.log(`known lists: ${listKeys}`);

    listLists(listKeys).then((groceryList) => setKnownLists(groceryList));
    document.title = "Cactus Groceries";
  }, []);

  const handleCreateList = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      const newGroceriesList = await createListIfNotExists(groceryListName);
      setIsSaving(false);
      setGroceryListName("");
      setKnownLists([...knownLists, newGroceriesList]);
      addKnownListKey(newGroceriesList.key);
    } catch (e) {
      setIsSaving(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroceryListName(e.target.value);
  };

  const handleDelete = (listKey: string) => {
    console.log(listKey);
    removeKnownListKey(listKey);
    setKnownLists((oldLists) => oldLists.filter((list) => list.key != listKey));
    // TODO: notify the server...
  };

  const lists = knownLists.map((list) => {
    return <ListRow list={list} onDelete={handleDelete} key={list.key} />;
  });

  const listsCard =
    knownLists.length > 0 ? (
      <Card marginTop={4}>
        <CardBody>
          <VStack spacing={3} align="stretch">
            {lists}
          </VStack>
        </CardBody>
      </Card>
    ) : undefined;

  return (
    <>
      <Heading size="lg" textAlign="center" marginBottom={3}>
        Cactus Groceries
      </Heading>

      <form onSubmit={handleCreateList}>
        <HStack spacing={2}>
          <Input
            name="name"
            onChange={handleChange}
            isRequired={true}
            value={groceryListName}
            placeholder="Grocery list name"
          />
          <Button type="submit" isLoading={isSaving} colorScheme="blue">
            Create list
          </Button>
        </HStack>
      </form>

      {listsCard}
    </>
  );
}
