import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  HStack,
  IconButton,
  Spacer,
  StackDivider,
  VStack,
  useEditableControls,
  ButtonGroup,
} from "@chakra-ui/react";
import { useState } from "react";

import { GroceriesList, GroceryItem } from "../Data";
import { GroceryItemAdder } from "./GroceryItemEditor";
import { GroceryItemViewer } from "./GroceryItemViewer";

interface GroceriesListViewerProp {
  groceriesList: GroceriesList;
  onItemEdit: (edittedItem: GroceryItem) => Promise<GroceryItem>;
  onItemCheck: (edittedItem: GroceryItem) => Promise<void>;
  onItemDelete: (deletedItem: GroceryItem) => Promise<void>;
  onItemAdd: (newItem: GroceryItem) => Promise<void>;
  onListTitleEdit: (newTitle: string) => void;
  onDeleteAllDoneItems: () => Promise<void>;
}

function GroceryListTitleViewer({
  title,
  onSave,
}: {
  title: string;
  onSave: (newTitle: string) => void;
}) {
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup size="sm">
        <IconButton
          icon={<CheckIcon />}
          aria-label="Save title"
          {...getSubmitButtonProps()}
        />
        <IconButton
          icon={<CloseIcon />}
          aria-label="Cancel"
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <ButtonGroup size="sm">
        <IconButton
          icon={<EditIcon />}
          aria-label="Edit title"
          {...getEditButtonProps()}
        />
      </ButtonGroup>
    );
  }

  return (
    <Editable
      defaultValue={title}
      textAlign="center"
      fontSize="2xl"
      fontWeight="bold"
      onSubmit={onSave}
    >
      <HStack>
        <Spacer />
        <EditablePreview />
        <EditableInput />
        <EditableControls />
        <Spacer />
      </HStack>
    </Editable>
  );
}

export function GroceriesListViewer({
  groceriesList,
  onItemEdit,
  onItemCheck,
  onItemDelete,
  onItemAdd,
  onListTitleEdit,
  onDeleteAllDoneItems,
}: GroceriesListViewerProp) {
  const [isDeletingAllDoneItems, setIsDeletingAllDoneItems] = useState(false);

  const notDoneItems = groceriesList.items.filter(
    (item: GroceryItem) => !item.done
  );
  const doneItems = groceriesList.items.filter(
    (item: GroceryItem) => item.done
  );

  const notDoneViewers = notDoneItems.map((item: GroceryItem) => (
    <GroceryItemViewer
      key={item.id}
      item={item}
      onEdit={onItemEdit}
      onCheck={onItemCheck}
      onDelete={onItemDelete}
    />
  ));

  const doneViewers = doneItems.map((item: GroceryItem) => (
    <GroceryItemViewer
      key={item.id}
      locked={isDeletingAllDoneItems}
      item={item}
      onEdit={onItemEdit}
      onCheck={onItemCheck}
      onDelete={onItemDelete}
    />
  ));

  const handleDeleteAllDoneItems = async function () {
    try {
      setIsDeletingAllDoneItems(true);
      await onDeleteAllDoneItems();
    } finally {
      setIsDeletingAllDoneItems(false);
    }
  };

  const crossedOffSection =
    doneViewers.length > 0 ? (
      <Card>
        <CardBody margin={0} paddingLeft={[1, 3, 5]} paddingRight={[1, 3, 5]}>
          <Center marginBottom={4}>
            <Heading size="md">Crossed off</Heading>
          </Center>
          <VStack divider={<StackDivider />} spacing={2} align="stretch">
            <Button
              colorScheme="red"
              variant="outline"
              leftIcon={<DeleteIcon />}
              onClick={handleDeleteAllDoneItems}
            >
              Delete all
            </Button>
            {doneViewers}
          </VStack>
        </CardBody>
      </Card>
    ) : (
      <></>
    );

  return (
    <>
      <Card marginBottom={4}>
        <CardBody margin={0} paddingLeft={[1, 3, 5]} paddingRight={[1, 3, 5]}>
          <Box marginBottom={2}>
            <GroceryListTitleViewer
              title={groceriesList.name}
              onSave={onListTitleEdit}
            />
          </Box>
          <Box marginBottom={4}>
            <GroceryItemAdder onAdd={onItemAdd} />
          </Box>
          <VStack divider={<StackDivider />} spacing={2} align="stretch">
            {notDoneViewers}
          </VStack>
        </CardBody>
      </Card>

      {crossedOffSection}
    </>
  );
}
