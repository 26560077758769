import React, { useState, useEffect } from "react";

import type { GroceriesList, GroceryItem } from "../Data";
import { Text, Box, Link } from "@chakra-ui/react";
import { GroceriesListViewer } from "./GroceriesListViewer";
import {
  createListItem,
  deleteListItem,
  fetchListWithItems,
  updateListItem,
  updateListName,
} from "../Api";
import { Flash } from "./Flash";
import type { FlashProp } from "./Flash";
import { SpinnerIcon } from "@chakra-ui/icons";
import { addKnownListKey } from "../LocalStorage";

export default function App() {
  const path = window.location.pathname.split("/");
  const groceriesListKey = path[1];
  const invalidPath = path.length !== 2;

  const [loaded, setLoaded] = useState(false);
  const [flash, setFlash] = useState({ show: false } as FlashProp);
  const [groceriesList, setGroceriesList] = useState({
    key: "unknown",
    name: "Loading...",
    items: [],
  } as GroceriesList);

  const showError = (text: string, title?: string) =>
    setFlash({
      show: true,
      status: "error",
      text: text,
      title: title,
    });

  useEffect(() => {
    if (!invalidPath && groceriesListKey.length > 0) {
      console.log(`fetching: ${groceriesListKey}`);
      fetchListWithItems(groceriesListKey)
        .then((groceriesList) => {
          setGroceriesList(groceriesList);
          setFlash({ show: false });
          setLoaded(true);

          // Memorize the key if it s a real key
          addKnownListKey(groceriesListKey);

          document.title = groceriesList.name;
        })
        .catch((e: Error) => {
          showError(e.toString(), "Failed to load list");
        });
    } else {
      // TODO: this is sometimes showing because of a race condition between how
      // the location has been replaced but the effect hook is being called
      // twice by react in strict mode?
      //
      // TODO: redirect user to home page.
      showError("Invalid url");
    }
  }, [invalidPath, groceriesListKey]);

  const handleEdit = async function (
    edittedItem: GroceryItem
  ): Promise<GroceryItem> {
    const idx = groceriesList.items.findIndex(
      (item) => item.name.toLowerCase() === edittedItem.name.toLowerCase()
    );
    if (idx !== -1 && groceriesList.items[idx].id !== edittedItem.id) {
      showError(`${edittedItem.name} already exists on the list.`);
      return edittedItem;
    }

    let updatedItem: GroceryItem;
    try {
      updatedItem = await updateListItem(groceriesList.key, edittedItem);
    } catch (e: any) {
      showError(e.toString(), "Failed to update item");
      throw e;
    }

    const newGroceriesList = {
      ...groceriesList,
      items: groceriesList.items.map((item) =>
        updatedItem.id === item.id ? updatedItem : item
      ),
    };

    setGroceriesList(newGroceriesList);
    return updatedItem;
  };

  const handleCheck = async function (edittedItem: GroceryItem): Promise<void> {
    const checkedItem = { ...edittedItem, done: !edittedItem.done };

    let updatedItem: GroceryItem;
    try {
      updatedItem = await updateListItem(groceriesList.key, checkedItem);
    } catch (e: any) {
      showError(e.toString(), "Failed to update item");
      throw e;
    }

    const newGroceriesList = {
      ...groceriesList,
      items: groceriesList.items.map((item) => {
        return edittedItem.id === item.id ? updatedItem : item;
      }),
    };

    setGroceriesList(newGroceriesList);
  };

  const handleDelete = async function (deletedItem: GroceryItem) {
    try {
      await deleteListItem(groceriesList.key, deletedItem.id);
    } catch (e: any) {
      showError(e.toString(), "Failed to delete item");
      throw e;
    }

    const newGroceriesList = {
      ...groceriesList,
      items: groceriesList.items.filter((item) => item.id !== deletedItem.id),
    };

    setGroceriesList(newGroceriesList);
  };

  const handleAdd = async function (newItem: GroceryItem): Promise<void> {
    const idx = groceriesList.items.findIndex(
      (item) => item.name.toLowerCase() === newItem.name.toLowerCase()
    );

    if (idx === -1) {
      let addedItem: GroceryItem;
      try {
        addedItem = await createListItem(groceriesList.key, newItem);
      } catch (e: any) {
        showError(e.toString(), "Failed to add item");
        throw e;
      }

      let newItems = [...groceriesList.items, addedItem];
      newItems.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        if (aName < bName) {
          return -1;
        }

        if (aName > bName) {
          return 1;
        }

        return 0;
      });

      setGroceriesList({ ...groceriesList, items: newItems });
    } else {
      // TODO: try to add quantity together!
      showError(`${newItem.name} already exists on the list`);
    }
  };

  const handleListTitleEdit = function (newTitle: string) {
    updateListName(groceriesList.key, newTitle).catch((e) => {
      // TODO: set the list title back
      showError(e.toString(), "Failed to update title");
    });
  };

  const handleDeleteAllDoneItems = async function () {
    if (!window.confirm("Are you sure you want to delete all items")) {
      return;
    }

    // TODO: batch delete API
    let deletePromises = groceriesList.items
      .filter((item) => item.done)
      .map((item) => {
        return deleteListItem(groceriesList.key, item.id);
      });

    for (let promise of deletePromises) {
      try {
        await promise;
      } catch (e: any) {
        showError(e.toString(), "Failed to delete");
        throw e;
      }
    }

    try {
      const newGroceriesList = await fetchListWithItems(groceriesListKey);
      setGroceriesList(newGroceriesList);
    } catch (e: any) {
      showError(e.toString(), "Failed to refresh grocery list");
      throw e;
    }
  };

  const handleFlashClose = () => {
    setFlash({ show: false });
  };

  const groceryListViewer = loaded ? (
    <GroceriesListViewer
      groceriesList={groceriesList}
      onItemAdd={handleAdd}
      onItemEdit={handleEdit}
      onItemDelete={handleDelete}
      onItemCheck={handleCheck}
      onListTitleEdit={handleListTitleEdit}
      onDeleteAllDoneItems={handleDeleteAllDoneItems}
    />
  ) : (
    <Box>
      <SpinnerIcon />
    </Box>
  );

  return (
    <>
      <Flash {...flash} onClose={handleFlashClose} />
      {groceryListViewer}
      <Text textAlign="center">
        <Link color="blue" href="/">
          Back to all lists
        </Link>
      </Text>
    </>
  );
}
