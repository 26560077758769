export interface GroceriesList {
  key: string;
  name: string;
  items: GroceryItem[];
}

export interface GroceryItem {
  id: string;
  name: string;
  quantity?: string;
  tags?: string;
  done?: boolean;
}

export const emptyGroceryItem = {
  id: "",
  name: "",
  quantity: "",
  tags: "",
  done: false,
};

// TODO: better types for the value here?
// TODO: runtime checks agains the keyof type because we use e.target.name for this function?
export function patchGroceryItem(
  item: GroceryItem,
  key: keyof GroceryItem,
  value: GroceryItem[keyof GroceryItem]
): GroceryItem {
  return {
    ...item,
    [key]: value,
  };
}
