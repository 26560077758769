import { Button, HStack, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import { emptyGroceryItem, GroceryItem, patchGroceryItem } from "../Data";

interface GroceryItemEditorProps {
  item: GroceryItem;

  // Returns the updated state the editor should display.
  onSave: (item: GroceryItem) => Promise<GroceryItem>;
  saveButtonLabel?: string;
  size?: string; // TODO: better types
  isDisabled?: boolean;
}

export function GroceryItemEditor({
  item,
  onSave,
  isDisabled = false,
  saveButtonLabel = "Save",
  size = "",
}: GroceryItemEditorProps) {
  const [editorItem, setEditorItem] = useState(item);
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    setEditorItem(
      patchGroceryItem(
        editorItem,
        e.target.name as keyof GroceryItem,
        e.target.value
      )
    );
  };

  const handleReset = function () {
    setEditorItem(item);
  };

  const handleSubmit = async function (e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsSaving(true);
    try {
      const newEditorItem = await onSave({ ...editorItem });
      setIsSaving(false);
      setEditorItem(newEditorItem);
    } catch (e) {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <HStack spacing={2}>
        <Input
          name="name"
          onChange={handleChange}
          isRequired={true}
          value={editorItem.name}
          placeholder="Item"
          size={size}
        />
        <Input
          name="quantity"
          onChange={handleChange}
          value={editorItem.quantity}
          placeholder="Qty"
          size={size}
          htmlSize={10}
          width="auto"
        />
        <Input
          name="tags"
          onChange={handleChange}
          value={editorItem.tags}
          placeholder="Tags"
          size={size}
          htmlSize={10}
          width="auto"
        />
        <Button
          isLoading={isSaving}
          isDisabled={isDisabled}
          size={size}
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          type="submit"
          isLoading={isSaving}
          isDisabled={isDisabled}
          size={size}
        >
          {saveButtonLabel}
        </Button>
      </HStack>
    </form>
  );
}

export function GroceryItemAdder({
  onAdd,
}: {
  onAdd: (item: GroceryItem) => Promise<void>;
}) {
  const handleSave = async function (item: GroceryItem): Promise<GroceryItem> {
    try {
      await onAdd(item);
    } catch (e) {
      console.error(e);
      return item;
    }
    return emptyGroceryItem; // Need to reset the editor to empty
  };

  return (
    <GroceryItemEditor
      item={emptyGroceryItem}
      onSave={handleSave}
      saveButtonLabel="Add"
      size="md"
    />
  );
}
