import { GroceriesList, GroceryItem } from "./Data";

export const apiServerBase =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/v1"
    : "/api/v1";

interface CreateListProps {
  name: string;
  key?: string;
}

type HTTPMethod = "GET" | "POST" | "DELETE" | "PUT";

async function jsonFetch<T>(
  method: HTTPMethod,
  url: string,
  data?: any
): Promise<T> {
  const response = await fetch(url, {
    method: method,
    body: data === undefined ? undefined : JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await response.json();

  if (!response.ok) {
    throw new Error(`${response.status}: ${json["error"]}`);
  }

  return json as T;
}

export function listLists(keys: string[]): Promise<GroceriesList[]> {
  let url = apiServerBase + "/lists?" + keys.map((key) => `k=${key}`).join("&");
  return jsonFetch<GroceriesList[]>("GET", url);
}

export function createListIfNotExists(
  name: string = "Groceries"
): Promise<GroceriesList> {
  const url = apiServerBase + "/lists";
  let data: CreateListProps = { name: name };

  return jsonFetch<GroceriesList>("POST", url, data).then(
    (list: GroceriesList) => {
      list.items = []; // server side doesn't return this field.
      return list;
    }
  );
}

export function updateListName(
  list_key: string,
  name: string
): Promise<GroceriesList> {
  const url = `${apiServerBase}/lists/${list_key}`;
  return jsonFetch<GroceriesList>("PUT", url, { name: name });
}

export function fetchListWithItems(list_key: string): Promise<GroceriesList> {
  const url = `${apiServerBase}/lists/${list_key}/items`;
  return jsonFetch<GroceriesList>("GET", url);
}

export function createListItem(
  list_key: string,
  item: GroceryItem
): Promise<GroceryItem> {
  const url = `${apiServerBase}/lists/${list_key}/items`;
  return jsonFetch<GroceryItem>("POST", url, item);
}

export function updateListItem(
  list_key: string,
  item: GroceryItem
): Promise<GroceryItem> {
  const url = `${apiServerBase}/lists/${list_key}/items/${item.id}`;
  return jsonFetch<GroceryItem>("PUT", url, item);
}

export function deleteListItem(
  list_key: string,
  item_id: string
): Promise<null> {
  const url = `${apiServerBase}/lists/${list_key}/items/${item_id}`;
  return jsonFetch<any>("DELETE", url).then((value: any) => null);
}
